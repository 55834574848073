<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="trial" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Invoice No</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th class="text-end">Total Invoice Amount</th>
                        <th class="text-end">Commission %</th>
                        <th class="text-end">Total Commission Amount</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(item, index) in details" :key="index">
                        <td>{{ item.bill_number }}</td>
                        <td>{{ item.sales_date }}</td>
                        <td>{{ item.contact_profile.full_name }}</td>
                        <td class="text-end">{{ item.total_amount ? commaFormat(item.total_amount) : 0 }}</td>
                        <td class="text-end">{{ item.commission_percent ? item.commission_percent : 0 }}</td>
                        <td class="text-end">{{ item.commission_amount ? commaFormat(item.commission_amount) : 0 }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="details.length == 0" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter'

const {commaFormat} = figureFormatter ()

const props = defineProps({
    details: {
        type: Array
    }
})
</script>

<style scoped>
#trial td{
    padding: 2px
}
#trial th{
    padding: 10px 3px;
}
</style>
